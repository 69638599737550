import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import ContactForm from '../contact/ContactForm.jsx'

class StandardMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = { pricesPopupReceived: false, modalVisible: false };
    this.handlePricesClick = this.handlePricesClick.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleModalClose(event) {
    setTimeout(() => {
      this.setState({ modalVisible: false, pricesPopupReceived: true });
    }, 1500);
  }

  handlePricesClick(event) {
    if (!this.state.pricesPopupReceived) {
      this.setState({ modalVisible: true });
    }
  }

  render() {

    let modal;
    var bg = this.props.isAtTop && this.props.shouldBeTransparent ? {} : { backgroundColor: "#000" };

    modal =
      <div>
        <Modal isOpen={this.state.modalVisible} className={this.props.className}>
          <ModalHeader>
            <img className="img-fluid" style={{ width: 250 }} src="/static/images/logos/logo-full-black-transparent.png" />
              <p className="lead">+33 6 46 08 55 66</p>
              <p style={{ fontVariant: "small-caps" }}>N'attendez plus, contactez-nous tout de suite pour connaitre nos tarifs !</p>
          </ModalHeader>
          <ModalBody>
            <p>Vous pouvez également nous laisser vos coordonnées pour profiter des meilleures offres du moment</p>
            <ContactForm message={false} callback={this.handleModalClose}/>
          </ModalBody>
        </Modal>
      </div>

    return (
      <nav className="navbar navbar-expand-md fixed-top text-light" style={bg}>
        {modal}
        <Link className="nav-link h3 my-0 mr-md-auto font-weight-normal text-light" to="/">
          <img className="img-fluid" style={{ width: 200 }} src="/static/images/logos/logo-full-white-transparent.png" />
        </Link>
        <Link className="nav-link mr-4 p-2 text-light text-center" to="/news">Actualités</Link>
        <Link className="nav-link mr-4 p-2 text-light text-center" to="/introduction">Votre 1ère expérience</Link>
        <Link className="nav-link mr-4 p-2 text-light text-center" to="/about">A propos du studio</Link>
        <Link className="nav-link mr-4 p-2 text-light text-center" to="/description">Sessions</Link>
        <Link className="nav-link mr-4 p-2 text-light text-center" to="/coachs">Coachs</Link>
        <Link className="nav-link mr-4 p-2 text-light text-center" to="#" onClick={this.handlePricesClick}>Tarifs</Link>
        <Link className="nav-link mr-4 p-2 text-light text-center" to="/events">Team Building</Link>
        <Link className="nav-link mr-4 p-2 text-light text-center" to="/contact">Contact</Link>
        <Link className="mr-2 btn btn-outline-light text-center" to="/sessions">Réserver votre session</Link>
        <a className="mr-2 btn btn-primary text-center" target="_blank" href="https://resa-bikindoor.deciplus.pro/sp_store.php">Acheter maintenant</a>
      </nav>
    );
  }
}
export default StandardMenu;