import React from 'react'
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ContactForm from '../contact/ContactForm.jsx'

class MobileMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = { pricesPopupReceived: false, modalVisible: false };
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handlePricesClick = this.handlePricesClick.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleModalClose(event) {
    setTimeout(() => {
      this.setState({ modalVisible: false, pricesPopupReceived: true });
    }, 1500);
  }

  handlePricesClick(event) {
    this.handleLinkClick();
    if (!this.state.pricesPopupReceived) {
      this.setState({ modalVisible: true });
    }
  }

  handleLinkClick(event) {
    this.refs.navcontrol.click();
  }

  render() {

    var bg = this.props.isAtTop && this.props.shouldBeTransparent ? {} : { backgroundColor: "#000" };

    var modal =
      <div>
        <Modal isOpen={this.state.modalVisible} className={this.props.className}>
          <ModalHeader>
            <img className="img-fluid" style={{ width: 250 }} src="/static/images/logos/logo-full-black-transparent.png" />
            <p className="lead">+33 6 46 08 55 66</p>
            <p style={{ fontVariant: "small-caps" }}>N'attendez plus, contactez-nous tout de suite pour connaitre nos tarifs !</p>
          </ModalHeader>
          <ModalBody>
            <p>Vous pouvez également nous laisser vos coordonnées pour profiter des meilleures offres du moment</p>
            <ContactForm message={false} callback={this.handleModalClose} />
          </ModalBody>
        </Modal>
      </div>

    return (
      <nav className="navbar navbar-light fixed-top" style={bg}>
        <Link className="navbar-brand text-light" to="/">
          <img className="img-fluid" style={{ width: 120 }} src="/static/images/logos/logo-full-white-transparent.png" />
        </Link>
        <button ref="navcontrol" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1"
          aria-controls="navbarSupportedContent1" aria-expanded="false"><span className="text-light"><i
            className="fa fa-bars"></i></span></button>
        <div className="collapse navbar-collapse" style={{ backgroundColor: "#000" }} id="navbarSupportedContent1">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link onClick={this.handleLinkClick} className="nav-link mr-4 p-2 text-light" to="/news">Actualités du studio</Link>
            </li>
            <li className="nav-item">
              <Link onClick={this.handleLinkClick} className="nav-link mr-4 p-2 text-light" to="/introduction">Votre première expérience</Link>
            </li>
            <li className="nav-item">
              <Link onClick={this.handleLinkClick} className="nav-link mr-4 p-2 text-light" to="/about">A propos du studio</Link>
            </li>
            <li className="nav-item">
              <Link onClick={this.handleLinkClick} className="nav-link mr-4 p-2 text-light" to="/description">Sessions</Link>
            </li>
            <li className="nav-item">
              <Link onClick={this.handleLinkClick} className="nav-link mr-4 p-2 text-light" to="/coachs">Coachs</Link>
            </li>
            <li className="nav-item">
              <Link onClick={this.handlePricesClick} className="nav-link mr-4 p-2 text-light" to="#">Tarifs</Link>
            </li>
            <li className="nav-item">
              <Link onClick={this.handleLinkClick} className="nav-link mr-4 p-2 text-light" to="/events">Team Building</Link>
            </li>
            <li className="nav-item">
              <Link onClick={this.handleLinkClick} className="nav-link mr-4 p-2 text-light" to="/contact">Contact</Link>
            </li>
            <li className="nav-item">
              <Link onClick={this.handleLinkClick} className="nav-link mr-4 p-2 text-light" to="/sessions">Réserver votre session</Link>
            </li>
            <li className="nav-item">
              <a className="mr-2 btn btn-primary text-center" target="_blank" href="https://resa-bikindoor.deciplus.pro/sp_store.php">Acheter maintenant</a>
            </li>
          </ul>
          {modal}
        </div>
      </nav>

    );
  }
}
export default MobileMenu;
