import React from 'react'
import ContactForm from './ContactForm.jsx'

import './Contact.css'
import ReactPixel from 'react-facebook-pixel'

class Contact extends React.Component {

    componentDidMount() {
        ReactPixel.track("Contact", "");
    }

    render() {
        return (
            <div>
                <div className="jumbotron contact-jumbotron contact-cover" style={{ backgroundImage: "url('/static/images/contact.jpg')" }}>
                    <div className="overlay"></div>
                    <div className="container">
                        <h1 className="text-uppercase font-weight-light">Contact</h1>
                        <p className="lead">Contactez-nous ou venez simplement nous rendre visite.</p>
                    </div>
                </div>
                <div className="container">
                    <div className="card-deck">
                        <div className="card box-shadow">
                            <div className="card-header bk-bg-blue text-white">
                                <h2 className="my-0 font-weight-normal"><i className="fa fa-envelope"></i> Nous contacter</h2>
                            </div>
                            <div className="card-body">
                                {<ContactForm message={true} />}
                            </div>
                        </div>
                        <div className="card box-shadow">
                            <div className="card-header bk-bg-blue text-white">
                                <h2 className="my-0 font-weight-normal"><i className="fa fa-home"></i> Nos coordonnées</h2>
                            </div>
                            <div className="card-body">
                                <img className="img-fluid" style={{ width: 250 }} src="/static/images/logos/logo-full-black-transparent.png" />
                                <p className="lead">+33 6 46 08 55 66<br />16 Avenue Louis de Broglie<br />33600 Pessac</p>
                                <p className="lead"><a href="mailto:bikindoor@gmail.com">bikindoor@gmail.com</a></p>
                                <p className="lead"><b>Venir en transports depuis Bordeaux Centre</b><br />
                                    TRAM B arrêt "Hôpital Haut Lévêque", puis :</p>
                                <ul>
                                    <li>BUS Ligne 36 arrêt "Parc Industriel"</li>
                                    <li>BUS Lignes 24/44/48 arrêt "Langevin"</li>
                                </ul>

                                <p className="lead"><b>Google Map</b> (clic pour ouvrir le plan)<br />
                                    <a href="https://goo.gl/maps/UnnsrxQET592" target="_blank" rel="noopener noreferrer" className="lead">
                                        <img src="/static/images/contact/map.png" className="img-fluid" />
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
export default Contact;
