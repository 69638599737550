import React from 'react'
import ReactPlayer from "react-player"

import './About.css'

class About extends React.Component {
  render() {

    var imgRatio = this.props.isMobileSize ? { minHeight: 450, maxHeight: 800 } : { minHeight: 400, maxHeight: 500 };

    return (
      <div>
        <div id="navigation" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="overlay"></div>
              <img class="d-block w-100" style={imgRatio} src="/static/images/about/about-1.jpg" />
            </div>
            <div class="carousel-item">
              <div class="overlay"></div>
              <img class="d-block w-100" style={imgRatio} src="/static/images/about/about-2.jpg" />
            </div>
            <div class="carousel-item">
              <div class="overlay"></div>
              <img class="d-block w-100" style={imgRatio} src="/static/images/about/about-3.jpg" />
            </div>
          </div>
          <a class="carousel-control-prev" href="#navigation" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          </a>
          <a class="carousel-control-next" href="#navigation" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
          </a>
        </div>
        <div class="container pt-5">
          <h1 className="text-center text-uppercase font-weight-light">Bik'Indoor révolutionne l’indoor cycling en proposant un entraînement complet.</h1>
          <p className="text-center lead">Entraînements, suivi personnalisé, résultats !</p>
        </div>
        <div className="container pt-5">
          <div className="row align-items-center">
            <div className="col-md-auto">
              <img className="round-image" src="/static/images/about/about-4.jpg" />
            </div>
            <div className="col">
              <h1 className="bk-blue" style={{ fontVariant: "small-caps" }}>A chaque objectif, son cours !</h1>
              <p className="lead">RPM, The Trip, Sprint, Cross training, Yog'indoor ou encore Box'indoor. Découvrez un large choix de séances pour répondre à vos besoins et vos objectifs. De plus, des variantes rendent les activités adaptées à tous (débutant, expert, séniors, femmes enceintes...).</p>
              <p className="lead">Une fois membre du club, libre à vous de réserver le cours collectif et le créneau qui vous conviennent le mieux.</p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-auto">
              <img style={{ maxWidth: "250px" }} src="/static/images/about/about-5.png" />
            </div>
            <div className="col">
              <h1 className="bk-blue" style={{ fontVariant: "small-caps" }}>Vous êtes au bon endroit !</h1>
              <p className="lead">Vous voulez des résultats durables ? Alors vous êtes véritablement au bon endroit. Nos coachs vous prennent en main de A à Z !</p>
              <p className="lead">Nous établirons un bilan de forme, grâce à notre impédancemètre, dès votre inscription. Pourquoi établir un bilan ? Car tout simplement, que vous souhaitiez perdre du poids ou développer votre musculature, ce bilan oriente véritablement le programme sportif à mettre en œuvre pour atteindre des résultats probants le plus rapidement et le plus efficacement possible.</p>
              <p className="lead">De plus, cela nous permettra d'établir également un plan nutritionnel ensemble.</p>
            </div>
          </div>
        </div>
        <div className="container pt-5">
          <h1 className="bk-blue" style={{ fontVariant: "small-caps" }}>Ils sont convaincus, pourquoi pas vous ?</h1>
          <div className='player-wrapper mt-2 mb-2'>
            <ReactPlayer
              className='react-player'
              volume="0.2"
              url="https://youtu.be/ORTmpH9sIZ8"
              width='100%'
              height='100%'
            />
          </div>
          <div className='player-wrapper mt-2 mb-2'>
            <ReactPlayer
              className='react-player'
              volume="0.2"
              url="https://youtu.be/efT6tkpLstA"
              width='100%'
              height='100%'
            />
          </div>
          <div className='player-wrapper mt-2 mb-2'>
            <ReactPlayer
              className='react-player'
              volume="0.2"
              url="https://www.youtube.com/watch?v=uiukOzQrp0Q"
              width='100%'
              height='100%'
            />
          </div>
        </div>
      </div>
    );
  }
}
export default About;