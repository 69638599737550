import React from 'react'
import { Link } from 'react-router-dom';
import ReactPlayer from "react-player"

import './Home.css'

class Home extends React.Component {
  render() {

    var imgRatio = this.props.isMobileSize ? { minHeight: 450, maxHeight: 800 } : { minHeight: 400, maxHeight: 500 };
    var bookBtnText = this.props.isMobileSize ? "Acheter maintenant" : "Acheter maintenant";

    return (
      <div>
        <div id="navigation" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="overlay"></div>
              <img className="d-block w-100" style={imgRatio} src="/static/images/home/home-4.png" />
              <div className="carousel-caption d-md-block">
                <h1 className="p-4 font-weight-light text-uppercase">Vivez l'expérience.</h1>
                <div className="pt-5 pb-5 container text-center">
                  <a className="mr-2 btn btn-primary text-center" target="_blank" href="https://resa-bikindoor.deciplus.pro/sp_store.php">{bookBtnText}</a>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="overlay"></div>
              <img className="d-block w-100" style={imgRatio} src="/static/images/home/home-5.png" />
              <div className="carousel-caption d-md-block">
                <h1 className="p-4 font-weight-light text-uppercase">Rejoignez-nous.</h1>
                <div className="pt-5 pb-5 container text-center">
                  <a className="mr-2 btn btn-primary text-center" target="_blank" href="https://resa-bikindoor.deciplus.pro/sp_store.php">{bookBtnText}</a>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="overlay"></div>
              <img className="d-block w-100" style={imgRatio} src="/static/images/home/home-6.png" />
              <div className="carousel-caption d-md-block">
                <h1 className="p-4 font-weight-light text-uppercase">Vivez l'instant.</h1>
                <div className="pt-5 pb-5 container text-center">
                  <a className="mr-2 btn btn-primary text-center" target="_blank" href="https://resa-bikindoor.deciplus.pro/sp_store.php">{bookBtnText}</a>
                </div>
              </div>
            </div>
          </div>
          <a className="carousel-control-prev" href="#navigation" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          </a>
          <a className="carousel-control-next" href="#navigation" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
          </a>
        </div>
        <div className="container pt-4 mb-4">
          <div className="text-center">
            <h1 className="text-center text-uppercase font-weight-light">BIK'INDOOR révolutionne l'indoor Cycling</h1>
            <h1 className="bk-blue" style={{ fontVariant: "small-caps" }}>See you on a bike !</h1>
          </div>
        </div>
        <hr />
        <div className="container pt-5">
          <h1 className="bk-blue" style={{ fontVariant: "small-caps" }}>Bik'Indoor c'est...</h1>
          <ul>
            <li className="lead">Un club ouvert 7j/7 de 7h à 22h.</li>
            <li className="lead">350 m² d’installation.</li>
            <li className="lead">Du matériel haut de gamme.</li>
            <li className="lead">Des sessions de sport unique.</li>
            <li className="lead">Brûler des calories et renforcer son corps.</li>
            <li className="lead">Un suivi en Coaching et Nutrition.</li>
          </ul>
          <div className='player-wrapper mb-2'>
            <ReactPlayer
              className='react-player'
              volume="0.2"
              playing loop url="https://youtu.be/3ZXVRFkvWmo"
              width='100%'
              height='100%'
            />
          </div>
        </div>
        <div className="container pt-5">
          <h1 className="bk-blue" style={{ fontVariant: "small-caps" }}>Parce que notre objectif est votre bien être...</h1>
          <ul>
            <li className="lead">Un suivi personnalisé de vis objectifs avec nos coachs.</li>
            <li className="lead">Notre bar de Boissons Détox ou énergisante pour remplir vos gourdes Bik’indoor.</li>
            <li className="lead">Des casiers individuels à clef pour ranger vos affaires .</li>
            <li className="lead">Un espace sanitaire avec ; douches individuelles, sèche-cheveux et crèmes de soin.</li>
          </ul>
          <p>
            <img className="m-2 mr-4 p-2 home-detail-image shadow" src="/static/images/home/home-detail-3.jpg" />
            <img className="m-2 mr-4 p-2 home-detail-image shadow" src="/static/images/home/home-detail-4.jpg" />
            <img className="m-2 mr-4 p-2 home-detail-image shadow" src="/static/images/home/home-detail-5.jpg" />
            <img className="m-2 mr-4 p-2 home-detail-image shadow" src="/static/images/home/home-detail-6.jpg" />
          </p>
        </div>
      </div >
    );
  }
}
export default Home;