import React from 'react'

import './Coachs.css'

class Coachs extends React.Component {
  render() {

    var mainImgRatio = this.props.isMobileSize ? { minHeight: 450, maxHeight: 800 } : { minHeight: 400, maxHeight: 500 };
    var subImgRatio = this.props.isMobileSize ? { maxWidth: 200 } : { maxWidth: 400 };

    return (
      <div>
        <div id="navigation" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="overlay"></div>
              <img class="d-block w-100" style={mainImgRatio} src="/static/images/coachs/coachs-1.jpg" />
            </div>
          </div>
        </div>
        <div className="container pt-5">
          <h1 className="text-center text-uppercase font-weight-light">Vos coachs</h1>
          <p className="text-center lead">Être dans un « bon studio » c’est aussi avoir l’encadrement de bons coachs.</p>
          <p className="text-center lead">Hautement qualifiés et passionnés, vos coachs Bik’indoor seront là pour vous poussez vers vos objectifs et vous faire devenir la meilleure version de vous-même !</p>
        </div>
        <div className="container">
          <div className="row pt-5">
            <div className="col-12">
              <h1 className="bk-blue text-uppercase">Caroline</h1>
              <p className="lead text-uppercase">34 ans - Coach - Pro Trainer Lesmills</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-auto">
              <img className="coach-image" src="/static/images/coachs/caroline.jpg" />
            </div>
            <div className="col">
              <p className="text-justify lead">Ancienne Gymnaste de niveau France, le plus important à ses yeux, c’est le mouvement, peu importe la discipline.</p>
              <p className="text-justify lead">Trainer Lesmills dans les concepts du Cycle, Caroline enseigne également le Yoga ainsi que les différentes activités relevant de la posture et de la mobilité.</p>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-12">
              <h1 className="bk-blue text-uppercase">Laurie</h1>
              <p className="lead text-uppercase">29 ans - Coach - Instructeur Lesmills</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-auto">
              <img className="coach-image" src="/static/images/coachs/laurie.jpg" />
            </div>
            <div className="col">
              <p className="text-justify lead">Passionnée depuis son plus jeune âge par le sport, mais surtout Karatéka de haut niveau, Laurie s’est ensuite révélé coach sportif.</p>
              <p className="text-justify lead">Après plusieurs années de management dans la remise en forme, elle se lance dans le Challenge de l’entreprenariat en ouvrant le premier studio d’indoor Cycling sur bordeaux ; BIK’INDOOR.</p>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-12">
              <h1 className="bk-blue text-uppercase">Joffrey</h1>
              <p className="lead text-uppercase">Coach</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-auto">
              <img className="coach-image" src="/static/images/coachs/joffrey.jpg" />
            </div>
            <div className="col">
              <p className="text-justify lead">Pratiquant de sport depuis toujours, Joffrey aime se lancer dans la découverte de nouveaux types d'efforts.</p>
              <p className="text-justify lead">Coach depuis 10 ans, véritable globe trotter du sport, il ressort d’une dernière expérience sur l’île de la Réunion où lui et son meilleur ami on monté un studio de coaching et small groupe.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Coachs;