import React from 'react'
import ReactPlayer from "react-player"

import './Description.css'

class Description extends React.Component {
  render() {

    var imgRatio = this.props.isMobileSize ? { minHeight: 450, maxHeight: 800, width: 225 } : { minHeight: 400, maxHeight: 500, width: 225 };
    var colSetup = this.props.isMobileSize ? "col-md-auto" : "col";

    return (
      <div>
        <div id="navigation" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="overlay"></div>
              <img className="d-block w-100" style={imgRatio} src="/static/images/description/description-1.jpg" />
            </div>
            <div className="carousel-item">
              <div className="overlay"></div>
              <img className="d-block w-100" style={imgRatio} src="/static/images/description/description-2.jpg" />
            </div>
            <div className="carousel-item">
              <div className="overlay"></div>
              <img className="d-block w-100" style={imgRatio} src="/static/images/description/description-3.jpg" />
            </div>
          </div>
          <a className="carousel-control-prev" href="#navigation" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          </a>
          <a className="carousel-control-next" href="#navigation" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
          </a>
        </div>
        <div className="container pt-5">
          <h1 className="text-center text-uppercase font-weight-light">Bik'Indoor est bien plus qu'un entraînement, c'est une véritable expérience.</h1>
          <p className="text-center lead">Comme des millions de personnes à travers le monde, venez suivre les meilleurs cours collectifs Cycle que le monde du fitness propose : venez découvrir l’unique studio powered by <br /><img className="p-4 img-fluid" style={{ width: 300 }} src="/static/images/description/lesmills-black.png" /></p>
        </div>

        <div className="container">
          <div className="row align-items-center">
            <div className="col">
              <img className="m-2 p-2 shadow" style={{ maxWidth: 350 }} src="/static/images/description/rpm-example.jpg" />
            </div>
            <div className="col">
              <img className="img-fluid mt-4 mb-4" style={{ maxWidth: 350, maxHeight: 100 }} src="/static/images/description/rpm.png" />
              <h2 className="font-weight-light">Le RPM™ est le cours de vélo indoor qui donne un maximum de résultats avec un minimum d'impact sur vos articulations. Sur une musique énergisante, prenez la route avec votre coach qui emmènera le peloton sur un parcours jonché de collines à grimper, de sprints et de plats. Vous contrôlez votre niveau de résistance et la vitesse pour améliorer votre condition physique au fil du temps. Pour un vrai sentiment de réussite, essayez le RPM dès aujourd'hui.</h2>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col">
              <img className="img-fluid mt-4 mb-4" style={{ maxWidth: 350, maxHeight: 100 }} src="/static/images/description/sprint.png" />
              <h2 className="font-weight-light">Vivez le sprint de haute intensité pendant 30 minutes en interval training (HIIT). Avec instructeur, basé sur le sprint du cyclisme, vous serez poussés pour atteindre des résultats optimaux.</h2>
            </div>
            <div className="col">
              <img className="m-2 p-2 shadow" style={{ maxWidth: 350 }} src="/static/images/description/sprint-example.jpg" />
            </div>
          </div>
          <div className="row align-items-center mb-2">
            <div className="col">
              <img className="m-2 p-2 shadow" style={{ maxWidth: 350 }} src="/static/images/description/virtual-example.jpg" />
            </div>
            <div className="col">
              <img className="img-fluid mt-4 mb-4" style={{ maxWidth: 350, maxHeight: 100 }} src="/static/images/description/virtual.png" />
              <h2 className="font-weight-light">Un entraînement en toute liberté, profitez du format de cours virtuel pour pratiquer le vélo comme vous le souhaitez.</h2>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col">
              <img className="img-fluid mt-2 mt-4 mb-4" style={{ maxWidth: 350, maxHeight: 100 }} src="/static/images/description/thetrip.png" />
              <h2 className="font-weight-light">Vivez une expérience sportive entièrement immersive qui combine un entraînement de vélo indoor avec un voyage à travers des mondes numériques. Avec son écran de cinéma et son système audio, THE TRIP pousse votre motivation et votre énergie au niveau supérieur !</h2>
            </div>
            <div className="col">
              <img className="m-2 p-2 shadow" style={{ maxWidth: 350 }} src="/static/images/description/thetrip-example.jpg" />
            </div>
          </div>
          <div className="row align-items-center mb-2">
            <div className="col">
              <img className="m-2 p-2 shadow" style={{ maxWidth: 350 }} src="/static/images/description/yogindoor-example.jpg" />
            </div>
            <div className="col">
              <img className="img-fluid mt-4 mb-4" style={{ maxWidth: 350, maxHeight: 100 }} src="/static/images/description/yogindoor.png" />
              <h2 className="font-weight-light">Yog'indoor est un concept unique d'une combinaison parfaite entre le vélo et le Yoga. Venez améliorer votre souplesse, votre posture et travaillez votre mobilité. Créons en vous un sentiment durable de bien être et de sérénité.</h2>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col">
              <h1><u>BOX'INDOOR</u></h1>
              <h2 className="font-weight-light">Ce programme propose un entraînement fonctionnel inspiré des sports de combats et des arts martiaux (Boxe, Karaté, Taekwondo et MMA - Mixed Martial Arts)
                Exploitant pleinement le principe d’entraînement à haute intensité, il améliore particulièrement la puissance, la vitesse, la coordination et l’endurance.
                Séance en small group / 30 minutes.</h2>
            </div>
            <div className="col">
              <img className="m-2 p-2 shadow" style={{ maxWidth: 350 }} src="/static/images/description/boxindoor-example.jpg" />
            </div>
          </div>
          <div className='text-center'><h1 className="mt-2 bk-blue" style={{ fontVariant: "small-caps" }}>Cross training</h1></div>
          <div className='player-wrapper'>
            <ReactPlayer
              className='react-player'
              volume="0.2"
              loop url="https://youtu.be/vOct4W0rtDcF"
              width='100%'
              height='100%'
            />
          </div>
          <div className='text-center'><h1 className="mt-2 bk-blue" style={{ fontVariant: "small-caps" }}>Yog'indoor</h1></div>
          <div className='player-wrapper'>
            <ReactPlayer
              className='react-player'
              volume="0.2"
              loop url="https://youtu.be/IvNbnOziYaQ"
              width='100%'
              height='100%'
            />
          </div>
        </div>
      </div>
    );
  }
}
export default Description;