import React from 'react'

import './Events.css'

class Events extends React.Component {
  render() {

    var imgRatio = this.props.isMobileSize ? { minHeight: 450, maxHeight: 800 } : { minHeight: 400, maxHeight: 500 };

    return (
      <div>
        <div id="navigation" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="overlay"></div>
              <img class="d-block w-100" style={imgRatio} src="/static/images/events/events-1.jpg" />
            </div>
          </div>
        </div>
        <div class="container pt-5">
          <h1 className="text-center text-uppercase font-weight-light">Il est temps de motiver vos collaborateurs !</h1>
          <p className="text-center lead">Passez un moment fédérateur et ludique avec eux tout en faisant un lien sur vos objectifs d'entreprise.</p>
        </div>
        <div className="container pt-5">
          <div className="row align-items-center">
            <div className="col-md-auto">
              <img style={{ maxWidth: 250 }} src="/static/images/events/events-5.png" />
            </div>
            <div className="col">
              <h1 className="bk-blue" style={{ fontVariant: "small-caps" }}>The Trip...</h1>
              <p className="lead">Faites voyager votre team grâce à Lesmills THE TRIP et partagé tous ensemble un moment fun et ludique.</p>
              <p className="lead">Toute l'équipe de Bik'indoor sera là pour immortaliser votre session à travers des photos et vidéos de vous et vos collaborateurs.</p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-auto">
              <img style={{ maxWidth: 250 }} src="/static/images/events/events-6.png" />
            </div>
            <div className="col">
              <h1 className="bk-blue" style={{ fontVariant: "small-caps" }}>...en toute convivialité !</h1>
              <p className="lead">Partager ensuite un moment convivial tous ensemble autour d'un brunch ou bien d’un apéritif.</p>
              <p className="lead">Fini les sessions bowling ou encore karting...Proposez une vraie expérience à votre Team !</p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-auto">
              <img style={{ maxWidth: 250 }} src="/static/images/events/events-4.png" />
            </div>
            <div className="col">
              <h1 className="bk-blue" style={{ fontVariant: "small-caps" }}>Pour toutes les occasions</h1>
              <p className="lead">Possibilité de privatiser également le studio pour anniversaire, EVG ou EVJF.</p>
            </div>
          </div>
        </div>
      </div >
    );
  }
}
export default Events;