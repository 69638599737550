import React from 'react'

import './News.css'
import ReactPlayer from "react-player"

class News extends React.Component {
  render() {

    var imgRatio = this.props.isMobileSize ? { maxWidth: window.outerWidth - 50 } : {};

    return (
      <div className="text-center">
        <div class="container mt-5 pt-5">
          <h1 className="bk-blue" style={{ fontVariant: "small-caps" }}>On parle de Bik'Indoor</h1>
          <p>Fitness challenge n°43 de oct/nov 2019</p>
          <img className="img-fluid" style={imgRatio} src="/static/images/news/news-2.jpg" /><br />
        </div>
      </div >
    );
  }
}
export default News;