import React from 'react'

class ContactForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = { alertVisible: false, formVisible: true };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.callback = this.callback.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            formVisible: false, alertVisible: true, alertType: "primary",
            alertMessage: "Envoi de votre message en cours, veuillez patienter..."
        });

        var form = event.target;
        fetch('/api/messages', {
            headers: { "Content-Type": "application/json" },
            method: 'post',
            body: JSON.stringify({
                "firstName": form.firstName.value,
                "lastName": form.lastName.value,
                "phone": form.phone.value,
                "mail": form.mail.value,
                "content": form.content.value,
                "subscribe": form.subscribe.checked
            })
        })
            .then(r => {
                this.setState({
                    formVisible: false, alertVisible: true, alertType: "success",
                    alertMessage: "Vos informations ont bien été transmises, nous vous recontacterons dans les meilleurs délais."
                });
                this.callback();
            })
            .catch(e => {
                this.setState({
                    formVisible: false, alertVisible: true, alertType: "danger",
                    alertMessage: "Une erreur est survenue lors de l'envoi des informations, nous vous invitons à réessayer ultérieurement."
                });
                this.callback();
            });
    }

    callback() {
        if (this.props.callback) {
            this.props.callback();
        }
    }

    render() {

        let alert;
        let form;
        let displayMessageField;
        let defaultMessage;

        if (this.props.message) {
            displayMessageField = { display: "block" };
            defaultMessage = "";
        } else {
            displayMessageField = { display: "none" };
            defaultMessage = "Votre message";
        }

        if (this.state.alertVisible) {
            alert = <div className={"alert alert-" + this.state.alertType + " alert-dismissable"} >
                {this.state.alertMessage}
            </div >
        }

        if (this.state.formVisible) {
            form = <form onSubmit={this.handleSubmit}>
                <div className="form-group form-row">
                    <div className="col">
                        <input type="text" className="form-control" name="lastName" placeholder="Nom" required />
                    </div>
                    <div className="col">
                        <input type="text" className="form-control" name="firstName" id="firstName" placeholder="Prénom" required />
                    </div>
                </div>
                <div className="form-group form-row">
                    <div className="col">
                        <input type="tel" className="form-control" name="phone" id="phone" placeholder="Téléphone" required />
                    </div>
                    <div className="col">
                        <input type="email" className="form-control" name="mail" aria-describedby="emailHelp" placeholder="Email" required />
                    </div>
                </div>
                <div style={displayMessageField} className="form-group">
                    <textarea defaultValue={defaultMessage} className="form-control" name="content" placeholder="Votre message" rows="4" required></textarea>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="subscribe" />
                    <label className="form-check-label" htmlFor="subscribe"><small>Je souhaite recevoir des informations sur les nouvelles offres Bik'Indoor</small></label>
                </div>
                <button type="submit" className="btn btn-dark mt-3">Valider et envoyer !</button>
            </form>
        }

        return (
            <div>
                {alert}
                {form}
            </div>
        );
    }
}
export default ContactForm;