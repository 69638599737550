import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'

import Main from './main/Main'

const container = document.getElementById('app')

const App = () => (
  <HashRouter>
    <Main />
  </HashRouter>
)

ReactDOM.render(<App />, container)