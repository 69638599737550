import React from 'react'

import './Footer.css'

class Footer extends React.Component {

    render() {
        return (
            <div className="footer text-muted">
                <hr />
                <div className="container">
                    <p className="d-inline">&copy; Bik'Indoor 2019 - 2023</p>
                    <div className="float-right">
                        <a className="mr-4 text-muted" target="_blank" href="https://www.facebook.com/bikindoor" style={{ fontSize: 24 }}><i className="fa fa-facebook-official"></i></a>
                        <a className="mr-4 text-muted" target="_blank" href="https://www.instagram.com/bikindoor/" style={{ fontSize: 24 }}><i className="fa fa-instagram"></i></a>
                    </div>
                </div>
            </div>
        );
    }
}
export default Footer;