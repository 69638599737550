import React from 'react'

import './Introduction.css'

class Introduction extends React.Component {
  render() {

    var imgRatio = this.props.isMobileSize ? { minHeight: 450, maxHeight: 800 } : { minHeight: 400, maxHeight: 500 };

    return (
      <div>
        <div id="navigation" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="overlay"></div>
              <img class="d-block w-100" style={imgRatio} src="/static/images/introduction/introduction-1.jpg" />
            </div>
          </div>
        </div>
        <div className="container pt-5">
          <h1 className="text-center text-uppercase font-weight-light">Votre 1ère session Bik'Indoor</h1>
          <p className="text-center lead">Il est important de bien préparer cette première séance afin de profiter de cette expérience inoubliable !</p>
        </div>
        <div className="container pt-5">
          <div className="row align-items-center">
            <div className="col-md-auto">
              <img className="round-image" src="/static/images/introduction/introduction-2.jpg" />
            </div>
            <div className="col">
              <h1 className="bk-blue" style={{ fontVariant: "small-caps" }}>Réservation</h1>
              <p className="lead">Chaque participant devra réserver son vélo à l’avance et cela maximum une heure avant le début de la session. Vous pourrez réserver votre vélo au club, par téléphone ou, une fois inscrit, sur votre application mobile.</p>
              <p className="lead">Nous vous laissons la possibilité de choisir l’emplacement de votre vélo dans le studio (sous réserve que ce dernier ne soit pas déjà pris).</p>
            </div>
          </div>
          <div className="row align-items-center mt-4">
            <div className="col-md-auto">
              <img className="round-image" src="/static/images/introduction/introduction-3.jpg" />
            </div>
            <div className="col">
              <h1 className="bk-blue" style={{ fontVariant: "small-caps" }}>Déroulement</h1>
              <p className="lead">Le Check-in se déroule dans l’idéal 15 minutes avant le début de la session. Vous serez alors encadré de votre Coach qui vous expliquera les réglages de votre vélo ainsi que le déroulement du cours.</p>
              <p className="lead">Nos sessions répondent aux besoins de tous les niveaux, il sera donc important de faire part à votre coach, de vos limitations physiques ou de vos blessures.</p>
            </div>
          </div>
        </div>
      </div >
    );
  }
}
export default Introduction;