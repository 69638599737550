import React from 'react'
import { Route, Switch } from 'react-router-dom'

import StandardMenu from './components/menu/StandardMenu'
import MobileMenu from './components/menu/MobileMenu'
import Footer from './components/footer'
import Home from './components/home'
import News from './components/news'
import Introduction from './components/introduction'
import About from './components/about'
import Description from './components/description'
import Coachs from './components/coachs'
import Events from './components/events'
import Contact from './components/contact'
import Sessions from './components/sessions'

import './Main.css'

class Main extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isAtTop: window.scrollY === 0,
      isMobileSize: window.outerWidth < 1200
    }
    this.handleScroll = this.handleScroll.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.getMenu = this.getMenu.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  }

  handleScroll(e) {
    window.scrollY === 0 ? this.setState({ isAtTop: true }) : this.setState({ isAtTop: false });
  }

  handleResize(e) {
    window.outerWidth < 1200 ? this.setState({ isMobileSize: true }) : this.setState({ isMobileSize: false });
  }

  getMenu(shouldBeTransparent) {
    if (this.state.isMobileSize) {
      return <MobileMenu shouldBeTransparent={shouldBeTransparent} isAtTop={this.state.isAtTop} />
    }
    return <StandardMenu shouldBeTransparent={shouldBeTransparent} isAtTop={this.state.isAtTop} />
  }

  render() {

    return (
      <div className="text-dark">
        {
          <Switch>
            <Route path="/news" render={() => this.getMenu(false)} />
            <Route render={() => this.getMenu(true)} />
          </Switch>
        }
        <main>
          {
            <Switch>
              <Route exact={true} path="/" render={() => <Home isMobileSize={this.state.isMobileSize} />} />
              <Route path="/news" render={() => <News isMobileSize={this.state.isMobileSize} />} />
              <Route path="/introduction" render={() => <Introduction isMobileSize={this.state.isMobileSize} />} />
              <Route path="/about" render={() => <About isMobileSize={this.state.isMobileSize} />} />
              <Route path="/description" render={() => <Description isMobileSize={this.state.isMobileSize} />} />
              <Route path="/coachs" render={() => <Coachs isMobileSize={this.state.isMobileSize} />} />
              <Route path="/events" render={() => <Events isMobileSize={this.state.isMobileSize} />} />
              <Route path="/contact" render={() => <Contact isMobileSize={this.state.isMobileSize} />}  />
              <Route path="/sessions" render={() => <Sessions isMobileSize={this.state.isMobileSize} />}  />
            </Switch>
          }
        </main>
        <Footer />

      </div>
    );
  }
}
export default Main;
